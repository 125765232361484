import React from "react"
import { graphql } from "gatsby"
import SEOArticle from "../components/SEO/article"
import SEOWebpage from "../components/SEO/webpage"
import Head from "../components/Head"
import Layout from "../components/Layout"
import ShareButtons from "../components/ShareButtons"
import ArticleFooter from "../components/ArticleFooter/index"
import FlexHalfRow from '../components/flexHalfBoxes/flexHalfRow'


const BlogBrandsTemplate = ({ data, pageContext, location }) => {
  const post = data.graphCmsBrandsWeService
  const equipmentType = post.equipmentType.replace(/_/g," ");
  const { slug } = pageContext
  const postUrl = data.site.siteMetadata.siteUrl + '/brands-we-service/' + slug + '/';
  const metaTitle = post.brand + " " + equipmentType + " Repair Service | " + data.site.siteMetadata.title;
  const metaDescription = "Let " + data.site.siteMetadata.title + " be your authorized " + post.brand  + " " +  equipmentType + " repair service provider for all your commercial bussiness needs. Our 24/7 support team services the Cincinnati, Northern Kentucky, and Indiana areas and look forward to being your local partner to keep your bussiness running strong";
  const ArticleTitle = post.brand + " " + equipmentType + " Repair Service";

  return (
    <Layout>
      <Head
        title={metaTitle}
        pageDesc={metaDescription}
        url={postUrl}
      />
      <SEOWebpage
        title={metaTitle}
        url={postUrl}
      />
      <SEOArticle
        title={ArticleTitle}
        date={post.publishedAt}
        meta_description={metaDescription}
        url={postUrl}
        articleImage={post.brandLogo.url}
      />
      <article className="services-post" >
        <header>
          <h1>{ArticleTitle}</h1>
          <span>{data.site.siteMetadata.title} | {post.publishedAt.slice(0, 10)}</span>
          <hr />
        </header>
        <div className="articleBody">
          <section>
            <p>Are you looking for a high-quality {post.brand} {equipmentType} certified technician who services the Cincinnati metro area?</p>
            <p>Do you need a professional 24/7 support who fully understands your {post.brand} {equipmentType}?</p>
            <h2>Authorized {post.brand} Maintenance and Repairs</h2>
            <p>Finding the right service tech for your commercial {equipmentType} is just as important as selecting your actual equipment. We are factory certified for all {post.brand} equipment and service the entire Cincinnati, Northern Kentucky, and Indiana Areas. Our job it to get your machines up and running.</p>
            <h2>Installation from the Experts</h2>
            <p>Don’t take any chances with your machines. Let {data.site.siteMetadata.title} eliminate any problems by making sure your {post.brand} {equipmentType}'s are installed and serviced correctly by an experienced company.</p>
          </section>
          <section dangerouslySetInnerHTML={{ __html: post.body.html }} ></section>
          <FlexHalfRow layout='imageContent'/>
          <div style={{ maxWidth: "280px", margin: "auto"}} className="external-button"><a href={post.brandUrl} target="_blank" rel="noopener noreferrer" ><strong>Manufacturers Website</strong></a></div>
          <img className="brandImage" src={post.brandLogo.url} alt={post.brand} />
        </div>
        <ArticleFooter />
        <ShareButtons
          pageLocation={postUrl}
          pageTitle={ArticleTitle}
        />
      </article>
    </Layout>
  )
}

export default BlogBrandsTemplate

export const pageQuery = graphql`
  query BlogBrandsBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        imageUrl
      }
    }
    graphCmsBrandsWeService(slug: {eq: $slug}) {
      slug
      brand
      brandUrl
      body {
        html
      }
      equipmentType
      publishedAt
      brandLogo {
        url
      }
    }
  }
`


